// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   backendservicehost: "https://apiqc.lapositivapruebas.applying.pe",
//   client_id: '6u5eaik0seakkkoqm2qs4q0edu',
//   client_secret: '1rgb61bk0k2f5oo10a5a6e61b1d3h3kgs5dspck9s0on9be7vo29',

//   loginUrl: 'https://authqc.lapositivapruebas.applying.pe/oauth2/authorize?' +
//     'identity_provider=azure&' +
//     'redirect_uri=http://localhost:4200/raf/callback&' +
//     'response_type=code&' +
//     'client_id=6u5eaik0seakkkoqm2qs4q0edu&' +
//     'scope=custodia/admin email openid',
//   cognitoTokenURL: 'https://authqc.lapositivapruebas.applying.pe/oauth2/token',
//   cognitoRevokeToken: 'https://authqc.lapositivapruebas.applying.pe/oauth2/revoke',

//   redirectURL: 'http://localhost:4200/raf/callback',
//   logout: 'https://authqc.lapositivapruebas.applying.pe/logout?' +
//     // 'identity_provider=azure&' +
//     'client_id=6u5eaik0seakkkoqm2qs4q0edu&' +
//     'response_type=code&' +
//     // 'scope=custodia/admin email openid&' +
//     'redirect_uri=http://localhost:4200/raf/callback',
//   production: false
// };

// export const environment = {
//   backendservicehost: "https://apidev.lapositivapruebas.applying.pe",
//   client_id: '6es9lacv6ed5ji3q0m00fcvo0o',
//   client_secret: '1628bbpd7c3ikfmuij5fnrarepede58rti8r3l5gqmjjua6e8u7g',
//
//   loginUrl: 'https://authdev-raf.auth.us-east-1.amazoncognito.com/oauth2/authorize?' +
//     'identity_provider=azure&' +
//     'redirect_uri=http://localhost:4200/raf/callback&' +
//     'response_type=code&' +
//     'client_id=6es9lacv6ed5ji3q0m00fcvo0o&' +
//     'scope=custodia/admin email openid',
//   cognitoTokenURL: 'https://authdev-raf.auth.us-east-1.amazoncognito.com/oauth2/token',
//   cognitoRevokeToken: 'https://authdev-raf.auth.us-east-1.amazoncognito.com/oauth2/revoke',
//
//   redirectURL: 'http://localhost:4200/raf/callback',
//   logout: 'https://authdev-raf.auth.us-east-1.amazoncognito.com/logout?' +
//     // 'identity_provider=azure&' +
//     'client_id=6es9lacv6ed5ji3q0m00fcvo0o&' +
//     'response_type=code&' +
//     // 'scope=custodia/admin email openid&' +
//     'redirect_uri=http://localhost:4200/raf/callback',
//   production: false
// };

export const environment = {
  backendservicehost: "https://api.raf.lapositiva.com.pe",
  client_id: '7llg4smoblm35iehkr7o7jtt9v',
  client_secret: 'rf94ss2vg0csu4dqi04t9n8v4eej9jnq57laigdllje9vj3tcng',

  loginUrl: 'https://authprd-raf/oauth2/authorize?' +
  'identity_provider=azure&' +
  'redirect_uri=https://authprd-raf.auth.us-east-1.amazoncognito.com/raf/callback&' +
  'response_type=code&' +
  'client_id=7llg4smoblm35iehkr7o7jtt9v&'+
  'scope=custodia/admin email openid',
  cognitoTokenURL: 'https://authprd-raf/oauth2/token',
  cognitoRevokeToken: 'https://authprd-raf/oauth2/revoke',
  redirectURL: 'https://authprd-raf/raf/callback',
  logout: 'https://authprd-raf.amazoncognito.com/logout?' +
    'client_id=7llg4smoblm35iehkr7o7jtt9v&' +
    'response_type=code&' +
    'scope=custodia/admin email openid' +
    '&redirect_uri=https://authprd-raf/raf/logout',
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
