<button type="button" class="close-modal btn btn-sm btn-outline-turquoise btn-circle" aria-label="Close"
    (click)="_activeModal.dismiss('Cross click')">
    <i class="fa fa-times"></i>
</button>
<div class="modal-body mt-5 mb-5 text-center">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-7">
            <div class="row">
                <div class="col-12" *ngIf="image">
                    <img [src]="'../../../../assets/images/alerts/'+image" [attr.alt]="image">
                </div>
                <div class="col-12" [ngClass]="{'mt-2': image}">
                    <h4 class="modal-title">{{title}}</h4>
                </div>
                <div class="col-12 mt-2" [ngClass]="{'text-center': showButtonCancell}">
                    <h6>{{text}}</h6>
                </div>
                <ng-container *ngIf="showButtonConfirm && showButtonCancell, else buttons">
                    <div class="col-12 mt-2">
                        <button type="button" class="btn btn-sm btn-danger mr-4"
                            (click)="_activeModal.close('Close click')">{{textButtonCancell}}</button>
                        <button type="button" class="btn btn-sm btn-success"
                            (click)="submmited()">{{textButtonConfirm}}</button>
                    </div>
                </ng-container>
                <ng-template #buttons>
                    <div class="col-12 mt-2">
                        <button type="button" class="btn btn-outline-success btn-rounded btn-padding-side"
                            (click)="submmited()">{{textButtonConfirm}}</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>